import React from 'react';
import { Link } from 'gatsby';
import SEO from '@codedrop/seo/esm/seo';
import Header from '@codedrop/header/esm/header';
import Layout from '@codedrop/layout/esm/layout';
import Block from '@codedrop/block/esm/block';
import BlockGraphic from '@codedrop/block-graphic/esm/block-graphic';
import Block2Col from '@codedrop/block-2-col/esm/block-2-col';
import Heading from '@codedrop/typography/esm/heading';
import AltCta from '@codedrop/cta/esm/alt-cta';
import Cta from '@codedrop/cta/esm/cta';
import Footer from '@codedrop/footer/esm/footer';
import setHTML from '@codedrop/utils/esm/utils';
import data from '../data/skpr.json';
import howItWorks from '../data/how-it-works.json';

const { seo, header, footer, cta, ctaForm } = data;
const {
  site,
  pageTitle,
  intro,
  cta: altCta,
  scalable,
  available,
  secure,
  adaptable,
  flexible,
  streamlined,
  observable,
} = howItWorks;
altCta.modalContent = ctaForm;

const HowItWorksPage = () => {
  return (
    <div>
      <SEO title={site.title} seo={seo} />
      <Header header={header} />
      <main>
        <Layout className="background--firework section--top-l">
          <Block
            title={{ tag: 'h1', text: pageTitle }}
            subtitle={{ tag: 'h2', ...intro.title }}
            modifier="hero"
          >
            <p className="text--l">{setHTML(intro.content)}</p>
            <p className="text--ms">{setHTML(intro.bottomline)}</p>
          </Block>
        </Layout>
        <Layout className="section--top-l">
          <div className="divider" />
        </Layout>
        {scalable && (
          <BlockGraphic
            title={scalable.title}
            subtitle={scalable.subtitle}
            graphic="image-work-efficiently.svg"
          >
            <div className="text--s">{setHTML(scalable.content)}</div>
            <Link className="button" to={scalable.button.link}>
              {scalable.button.text}
            </Link>
          </BlockGraphic>
        )}
        <AltCta cta={altCta} />
        {available && (
          <BlockGraphic
            title={available.title}
            subtitle={available.subtitle}
            graphic="image-highly-available.svg"
            reverse
          >
            <div className="text--s">{setHTML(available.content)}</div>
          </BlockGraphic>
        )}
        {secure && (
          <>
            <Layout className="section--top-l">
              <Heading headingClass="heading--special text--centered" tag="h2">
                {setHTML(secure.title)}
              </Heading>
            </Layout>
            <Block2Col
              variant="offset"
              secondCol={
                <Block title={{ tag: 'h2', text: secure.decoupled.title }}>
                  <p className="text--ms">{secure.decoupled.content}</p>
                  {secure.decoupled.button && (
                    <a className="button" href={secure.decoupled.button.link}>
                      {secure.decoupled.button.text}
                    </a>
                  )}
                </Block>
              }
              firstCol={
                <Block title={{ tag: 'h2', text: secure.security.title }}>
                  <p className="text--ms">{secure.security.content}</p>
                  {secure.security.button && (
                    <a className="button" href={secure.security.button.link}>
                      {secure.security.button.text}
                    </a>
                  )}
                </Block>
              }
            />
          </>
        )}
        {flexible && (
          <BlockGraphic
            title={flexible.title}
            subtitle={flexible.subtitle}
            graphic="image-flexible.svg"
            reverse
          >
            <div className="text--s">{setHTML(flexible.content)}</div>
          </BlockGraphic>
        )}
        {adaptable && streamlined && (
          <Block2Col
            firstCol={
              <Block
                title={{ tag: 'h2', text: adaptable.title }}
                subtitle={{ tag: 'h3', text: adaptable.subtitle }}
              >
                <div className="text--s">{setHTML(adaptable.content)}</div>
              </Block>
            }
            secondCol={
              <Block
                title={{ tag: 'h2', text: streamlined.title }}
                subtitle={{ tag: 'h3', text: streamlined.subtitle }}
              >
                <div className="text--s">{setHTML(streamlined.content)}</div>
              </Block>
            }
          />
        )}
        {observable && (
          <BlockGraphic
            title={observable.title}
            subtitle={observable.subtitle}
            graphic="image-observable.svg"
          >
            <div className="text--s">{setHTML(observable.content)}</div>
            {observable.button && (
              <a className="button" href={observable.button.link}>
                {observable.button.text}
              </a>
            )}
          </BlockGraphic>
        )}
        <Cta cta={cta} />
      </main>
      <Footer footer={footer} />
    </div>
  );
};

export default HowItWorksPage;
